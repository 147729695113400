import { Component, OnInit} from '@angular/core';


import {MatDialog} from '@angular/material/dialog';


import { ViewChild, OnChanges, Inject} from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { temporaryDeclaration } from '@angular/compiler/src/compiler_util/expression_converter';
import swal from'sweetalert2';
import * as jsPDF from "jspdf";
//import * as jsbard from 'jsbarcode';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import Swal2 from 'bootstrap-sweetalert/dist/sweetalert.js'

import {MatMenuModule} from '@angular/material';

import 'sweetalert2/src/sweetalert2.scss'

import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MetodosComponent } from '../services/metodos/metodos.component';
import { fromEvent } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

//import 'angular-base64/angular-base64'; 
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss'],
  providers:[MetodosComponent ],
})
@Directive({
  selector: '[appDragDrop]'
})

export class InformacionComponent implements OnInit {
 

  constructor(private route: ActivatedRoute,public api:MetodosComponent, private router:Router,private httpClient: HttpClient,private dialog: MatDialog) {

    let today = new Date();
    this.ver("today es "+today)


    //var file=this.fechaconv_ymd(this.planModel.start_time)
   // this.ver("file es "+file)

    //this.cargardata()

    this.route.params.subscribe(params=>
      {
        this.doc = params['doc'];
        this.depocod = params['depocod'];
this.ver("va a imp "+this.doc+" con depocod "+this.depocod)
      // this.cargardata()
        //this.clicod = JSON.parse(params['clicod']);
      }
      );




   }

   ver(a){

    console.log(a)


   }

   planModel: any = {start_time: new Date() };

  
   fechaconv_ymd(a){


   // return this.datepipe.transform(a, 'yyyy-MM-dd'); //whatever format you need. 

  }
 
   public mensaje(a,tipo){

   
    if(tipo=="warning"){
  
      Swal.fire({
        title: 'Error!',
        text: a,
        icon: 'warning',
        confirmButtonText: 'ok'
      }) 
    }
  
      if(tipo=="error"){
  
        Swal.fire({
          title: 'Error!',
          text: a,
          icon: 'error',
          confirmButtonText: 'ok'
        }) 
    }
  
  
  
    if(tipo=="success"){
  this.ver("entrara a succes")
      Swal.fire({
        title: 'OK!',
        text: a,
        icon: 'success',
        confirmButtonText: 'ok'
      }) 
  
    }
    
  /* 
    if(tipo=="success")Swal.fire('Modificado exitosamente')
    if(tipo=="warning")Swal.fire('ocurrio un error')
  
  
  
      var tipo2=tipo;
      if(tipo=="warning")tipo2="AVISO"
      if(tipo=="success")tipo2="CORRECTO"
      
          Swal.fire({
                type: tipo,
            title: tipo2,
            text: a,
            timer: 2000
          })
         */
  
       
  
  
    }
    cargardata()
  {
/* 
    this.depocod="126"
    this.doc="14867409"
 */

    //this.depocod="103"
   // this.doc="14896661"


this.ver("depocod= "+this.depocod+" documlk= "+this.doc)
    var url = "http://192.177.50.67/api_fel/index.php/api/Factura/factura_empresa";


    var data = new FormData();
    data.append("empresa","1");
    data.append("depocod",this.depocod);
    data.append("documlk",this.doc);
    data.append("fecha","18/12/2019");


          this.api.data_postip(url,data)
          .subscribe((data1: any) => {

           this.ver("resp es "+JSON.stringify(data1))

           var paso=data1["status"]
           if(paso==true){

            if(data1["cabecera"]!=null)var cab=data1["cabecera"]

            if(data1["cabecera"]!=null){
                  //this.ver("nit es "+cab.nit_empresa)
                  if(cab.nit_empresa!=null)this.nit=cab.nit_empresa
                  if(cab.razon!=null)this.name=cab.razon
                  if(cab.nomemp!=null)this.name2=cab.nomemp
                  if(cab.direccion!=null)this.direccion=cab.direccion

                  if(cab.fecha_emis!=null)this.fechaemis=cab.fecha_emis
                  if(cab.nit!=null)this.nit1=cab.nit
                  if(cab.nomcli!=null)this.name1=cab.nomcli
                  if(cab.dircli!=null)this.direccion2=cab.dircli
                  if(cab.tip_doc!=null)this.tip_doc=cab.tip_doc

                  

                  if(data1["detalle"]!=null) this.element=data1["detalle"]
                  if(cab.firma!=null)this.firma=cab.firma

                  if(cab.numero!=null)this.numero=cab.numero
                  if(cab.totletras!=null)this.enletras=cab.totletras
                  if(cab.serie!=null)this.serie=cab.serie

                //  if(cab.documst!=null)this.moneda=cab.documst


                  this.ver("va a entra a  tc")
                  if(cab.tasa!=null)this.tasa=cab.tasa
                  var aa=+this.tasa
                // var aa=2
                  this.moneda=+aa.toFixed(0)

                  this.ver("va a salir a  tc")


                  if(cab.subtotal!=null)this.subtotal=cab.subtotal


                  if(cab.clicod!=null)this.clicod=cab.clicod
                  if(cab.elaborado!=null)this.elaborado_por=cab.elaborado
                  if(cab.responsable!=null)this.vendedor=cab.responsable
                  if(cab.cond_pago!=null)this.cond_pago=cab.cond_pago
                  if(cab.no_orden!=null)this.pedido=cab.no_orden

                  if(cab.serie_adm!=null)this.serie_adm=cab.serie_adm
                  if(cab.numero_adm!=null)this.numero_adm=cab.numero_adm
                  if(cab.num_acceso!=null)this.num_acceso=cab.num_acceso
                  if(cab.dias_cr!=null)this.dias_cr=cab.dias_cr


                  


                  //si es dolares
                  if(this.moneda!=1){
                   // this.ver("no es igual con moneda= ---"+this.moneda+"---")
                    if(cab.incoterm!=null) this.INCOTERM=cab.incoterm
                    if(cab.cod_dest!=null)  this.CODIGODESTINA=cab.cod_dest
                    if(cab.cod_compra!=null)  this.CODCOMPRADOR=cab.cod_compra
                     //this.OTRAREF=cab.cod_export
                     if(cab.cod_export!=null)  this.CODEXPORT=cab.cod_export

                  }


      


                  this.imprimir2()
                  this.ver("series es "+this.serie+" numero es "+this.numero+" enletras es "+this.enletras)

            }
            
           }else{


            var msn=data1["message"]
            this.mensaje(msn,"warning")

           }

          
          },
          error =>{ 
            console.error("couldn't post because", JSON.stringify(error));      
            this.mensaje("datos no encontrados","error")

            // this.openSnackBar('No existe el doc ','ERROR'); 
          })

  }



   serie:string=""
   numero:string=""
   noaut:string=""
   fechaemision:string=""

   nit:string=""
   name:string=""
   name2:string=""
   direccion:string=""
//cliente
   nit1:string=""
   fechaemis:string=""
   name1:string=""
   direccion2:string=""
   tip_doc:string=""

   enletras:string=""

   //fact
   element:any

   moneda:number=0
   tasa:string="0"



   //cliente
   totletras:string=""
   firma:string=""

   //nuevos campos
   elaborado_por:string=""
   clicod:string=""
   vendedor:string=""
   cond_pago:string=""
   pedido:string=""
   subtotal:string=""
   fecha_emis:string=""
   serie_adm:string=""
   numero_adm:string=""
   num_acceso:string=""

  //solo para exportacion
  
INCOTERM:string=""
DESTINATARIO:string=""
CODIGODESTINA:string=""
NOMBCOMPRADOR :string=""
DIRCOMPRADOR:string=""
CODCOMPRADOR :string=""
OTRAREF:string=""
CODEXPORT:string=""
DIRDEST:string=""

dias_cr:string=""

   generatePDF(img,doc){

    doc.addImage(img, 'JPEG', 0, 0, 100, 50);
  
  }
   


  header(doc,y){

    var img = new Image();
    //img.src = './assets/logocelasa.jpg';
    
img.src='http://192.177.50.50/test/logocelasa.jpg'
y=y-5 
    doc.addImage(img, 'JPEG', 4  , y, 25  , 25);
y=y+5


    doc.setFont("helvetica");
    doc.setFontType("bold");
    doc.setFontSize(10); 
    doc.text("Documento Tributario Electronico",75  ,y);
    doc.setFontSize(16); 
    y = y +6;

    if(this.tip_doc=='F1')doc.text("FACTURA ",90  ,y);
   else doc.text("FACTURA CAMBIARIA ",70  ,y);
   y = y -1;
   doc.setFontType("normal");
   doc.setFontSize(9); 
   y+=7
   var fechaemision="10/12/2019"

   doc.setFontType("bold");  
   doc.setFontSize(11);
   doc.text("Serie: "+this.serie+ " Numero: "+this.numero,65   ,y);
  
   doc.setFontSize(9);
   y+=5
   doc.text("Numero de autorización: "+this.firma,50   ,y);
   y+=5
   doc.text("Fecha de Emisión: "+this.fechaemis,75   ,y);
   doc.text(this.doc,145   ,y);
   doc.setFontType("normal");
   this.line(doc,y,2)


   //esto se agreago
   y+=2
   doc.setFillColor(218,218,221);
   doc.rect(5, y, 200, 6, 'F');
   y+=5
   doc.setFontType("bold");
   doc.setFontSize(9);
   doc.text("DATOS DEL VENDEDOR ",80,y);
   doc.setFontType("normal");
   doc.setFontSize(8);
   y+=5
   doc.text("NIT del contribuyente: ",8,y);
   doc.text("Nombre, razón o denominación social del contribuyente: ",70,y);

   //line
   this.line(doc,y,2)

   doc.line(50, y-4, 50, y+20);//vertical

   //end line
   y+=5
   doc.text(this.nit,14,y);        
   doc.text(this.name,55,y);
     //line
   this.line(doc,y,2)
    y+=5

   doc.setFontSize(7);
   doc.text("NOMBRE DEL ESTABLECIMIENTO",7,y);        
   doc.setFontSize(9);
    doc.text(this.name2,55,y);
    doc.setFontSize(7);
   y+=3
   doc.text("COMERCIAL",7,y);
   doc.setFontSize(9);
  this.line(doc,y,1)
  y+=4
  doc.text("DIRECCION",15,y);        
 /*  doc.text(this.direccion,55,y);     
 */





  var descri01=this.direccion
  var nomber01=82
    this.ver("direccon encabe len es "+this.direccion.length)
  if(this.direccion.length>82){
    var descrp=""
    var len=descri01.length
    var slices=len/nomber01
    var piec=Math.round(slices)
    this.ver("numeros es "+piec)
    var yy=0
    for (let i = 0; i <=piec; i++) {

     if((yy+nomber01)<len) descrp=descri01.substring(yy,yy+nomber01)
     else descrp=descri01.substring(yy,len)

     this.ver("va a tirar "+descrp+" en estado "+i+" con y="+y+"en 437")
     this.ver("con len es "+descrp.length)

     doc.text(""+descrp,55,y);

     if(i==piec)  this.line(doc,y,2)
     y=y+5
      yy=yy+nomber01
      //saltos+=1
    }
    this.line(doc,y,2)
  }else  {
    doc.text(this.direccion,55,y)
    this.line(doc,y,2)
    y+=2
  }









  
 // y+=2
  doc.setFillColor(218,218,221);
  doc.rect(5, y, 200, 5, 'F');
  
  //SEGUNDO BLOQUE
  y+=4
          
  doc.setFontType("bold");

  doc.text("DATOS DEL COMPRADOR ",79,y);
  doc.setFontType("normal");

  y+=5

  var x=50
  //doc.setFontType("bold");
  doc.text("NIT del contribuyente: ",8,y);
  doc.setFontType("normal");
  doc.text(this.nit1,x,y);
  x=x+20
 // doc.setFontType("bold");
  doc.text("CLIENTE: ",x,y);
  doc.setFontType("normal");
  x=x+17
  doc.text(this.clicod,x,y);
//  doc.setFontType("bold");
  x=x+25
  doc.text("ELABORADO POR: ",x,y);
  doc.setFontType("normal");
  x=x+30
  doc.text(this.elaborado_por,x,y);


  //line
  this.line(doc,y,2)
 // doc.line(50, y-4, 50, y+12);//vertical

  //end line
  y+=5
  doc.text("NOMBRE:",8,y);        
  doc.text(this.name1,50,y);
    //line
  this.line(doc,y,2)
   y+=5
 //  this.direccion2="AVENIDA ELENA 3-23 ZALDAZA SAN JUAN Y BOULEVERD LIVERACION LLEGANDO A RECITALES PASNDO POR LA CENTRA SUR Y LLEGANDO AL ESTADIO EN LA PARTE DE ENFRETN E YSALIDA AL ATANTICO Y PERIFIRIO SUR DIAGLONA 9"
  doc.text("DIRECCION:",8,y)   
  //doc.text(this.direccion2,50,y)
  

  var descri0=this.direccion2
  var nomber0=81
  //  this.ver("direccon2len es "+this.direccion2.length)
  if(this.direccion2.length>81){
    var descrp=""
    var len=descri0.length
    var slices=len/nomber0
    var piec=Math.round(slices)
    this.ver("numeros es "+piec)
    var yy=0
    for (let i = 0; i <=piec; i++) {

     if((yy+nomber0)<len) descrp=descri0.substring(yy,yy+nomber0)
     else descrp=descri0.substring(yy,len)

     this.ver("va a tirar "+descrp+" en estado "+i+" con y="+y+"en 527")
     doc.text(""+descrp,50,y);

     if(i==piec)  this.line(doc,y,2)
     y=y+5
      yy=yy+nomber0
      //saltos+=1
    }
    this.line(doc,y,2)
  }else  {
    doc.text(this.direccion2,50,y)
    this.line(doc,y,2)
    y+=5
  }






 
  doc.setFontSize(9);
  x=30
//  doc.setFontType("bold");
  doc.text("VENDEDOR: ",8,y);
  doc.setFontType("normal");
  doc.text(this.vendedor,x,y);
  x=x+20
  //doc.setFontType("bold");
  doc.text("CONDICIONES DE PAGO: ",x,y);
  doc.setFontType("normal");
  x=x+40
  doc.text(this.cond_pago,x,y);
//   doc.setFontType("bold");
  x=x+35
  doc.text("PEDIDO NO.: ",x,y);
  doc.setFontType("normal");
  x=x+25
  doc.text(this.pedido,x,y);

 // doc.setFontSize(7);
///  doc.text("Tasa de cambio",170,y);     
  
this.line(doc,y,2)
var clie=+this.tasa;

 var cambio=+clie.toFixed(2);


y+=5
doc.setFontSize(9);
x=10
// doc.setFontType("bold");
doc.text("TASA DE CAMBIO: ",8,y);
doc.setFontType("normal");
doc.text(""+cambio,40,y);
this.line(doc,y,2)


this.ver("salio de alli")
   return y
  }

 
  
doc:string=""
depocod:string=""
imprimir(){
  this. cargardata()
}




  getOrderNumber() {
  const number = 'R' + Math.floor(Math.random() * 100000000000);
  return number;
  }

  getBarcode() {
    //JsBarcode(".barcode").init();
  }



    


   imprimir2(){
   

    var doc = new jsPDF();
    var pageHeight= doc.internal.pageSize.height;

    var img = new Image();

  //  var serie="0EACA9FB"
   // var numero="508511200"
    //var noaut="AAAAAAAAA-BBBBBBBBB-CCCCCCCCCC-DDDDDDDD"
  

   doc.setLineWidth(0.5); 
   doc.line(30, 2, 30, 24);
    //--------------------------posix--posy--ancho---alto
    //
var y=7



     y= this.header(doc,y)
     
    
        //TERCER BLOQUE
        y+=5
        doc.setFillColor(218,218,221);
        doc.rect(5, y, 200, 6, 'F');  
        y+=4                
        doc.setFontType("bold");
        doc.text("DESCRIPCION DEL DOCUMENTO ",70,y);
        doc.setFontType("normal");


        y+=2      


        var x=20
        doc.line(x, y, x, y+6);//vertical
      //  doc.line(x+100, y,x+100, y+10);
        x=x+105
        doc.line(x, y, x, y+6);
        x=x+20
        doc.line(x, y, x, y+6);
        //x=x+17
       // doc.line(x, y, x, y+6);
       // x=x+19
       // doc.line(x, y, x, y+6);
       // x=x+17
       // doc.line(x, y, x, y+6);
        x=x+19
        doc.line(x, y, x, y+6);
        x=x+19
       doc.line(x, y, x, y+6)
        x=x+22
        doc.line(x, y, x, y+6)
        this.line(doc,y,6)

        x=5
        y+=3 
        doc.setFontSize(7);

        doc.text("CANTIDAD ",x,y);
        x=x+45
        doc.text("DESCRIPCION",x+5,y);
        x=x+80
        y=y-1
        doc.text("PRECIO ",x,y);
        y=y+3
        doc.text("UNITARIO",x,y);
        y=y-3
        x=x+19
        doc.text("SUB-TOTAL",x,y);
        x=x+19
       // doc.text("DESCUENTO",x,y);
      
        doc.text("VALOR ",x,y);
        y=y+3
        doc.text("EXENTO",x,y);
        y=y-3
     //   x=x+17
       // doc.text("DESC OTROS",x,y);
        y=y+3
     //   doc.text("IMPUESTOS",x,y);
        y=y-3

     //   x=x+19
      //  doc.text("OTROS ",x,y);
        y=y+3
       // doc.text("IMPUESTOS",x,y);
        y=y-3

        x=x+19
        doc.setFontType("bold");

        if(this.moneda==1)doc.text("TOTAL Q",x,y);
        else              doc.text("TOTAL $",x,y);
      
        doc.setFontType("normal");


        // descripcion
        y=y+7
        x=10

        this.line(doc,y,2)
        if(this.element!=null || this.element!="undefined"){

       //   for (let i = 0; i <22; i++) {
           var jk=0

            this.element.forEach(value => {
           
           // if(jk==0){
              jk+=1
              if (y>=pageHeight-35)
            {
              y=7;
              doc.addPage();                                           //x y footer, x y header
              this.header(doc,y)
              //this.line(doc,y,2)
              doc.setFontSize(8);
              y=105;
            }

              this.ver("cantidad es "+value.cantidad+" descri es "+value.descri)
    
              doc.text(""+value.cantidad,x,y);
              x=x+12
              var descri=""
              
              if(value.descri!=null) descri=value.descri.trim()

              this.ver("descri es ---"+descri+"---")
            //  
            var saltos=0
            var nomber=65
             // this.ver("long es "+len)
              if(descri.length>nomber){
                var descrp=""
                var len=descri.length
                var slices=len/nomber
                var piec=Math.round(slices)
                this.ver("numeros es "+piec)
                var yy=0
                for (let i = 0; i <=piec; i++) {

                 if((yy+nomber)<len) descrp=descri.substring(yy,yy+nomber)
                 else descrp=descri.substring(yy,len)

                 this.ver("va a tirar "+descrp+" en estado "+i+" con y="+y+"en 765")
                 doc.text(""+descrp,x,y);
                 y=y+5
                  yy=yy+nomber
                  saltos+=1
                }
              
              }else  doc.text(""+descri,x,y);


              this.line(doc,y,2)
              if(saltos>0){
                for (let i = 0; i <saltos; i++) {

                  y=y-5
                }

              }


            //  doc.text(""+descrp,x,y);
              x=x+116
              doc.text(""+value.preciou,x,y,'right');
                x=x-2
                x=x+22
                doc.text(""+value.subtotal,x,y,'right');
                x=x+22

                if(this.moneda!=1) doc.text(""+value.subtotal,x,y,'right');
/* 
if(this.moneda=='V') x=x+22
              else {
                x=x+22
                doc.text(""+value.subtotal,x,y,'right');
                x=x+42
              } 
             
              doc.text(""+value.subtotal,x,y,'right');
            
            
              if(this.moneda=='V')x=x+40
              else {
                x=x+40
                doc.text(""+value.subtotal,x,y,'right');

              }

 */


              x=x+20
              doc.text(""+value.subtotal,x,y,'right');
    
              if(saltos>0){
                for (let i = 0; i <saltos; i++) {

                  y=y+5
                }

              }else   y=y+5
              x=10
  
            
             })


             y+=1
             var mon="Q"
           if(this.moneda!=1) mon="$"
             doc.text("VALOR TOTAL "+mon+":",8,y)   
             doc.text(""+this.subtotal,30,y)
     
             
     
     
             this.line(doc,y,2)

        
  
        }
      

        y+=3
         doc.setFillColor(218,218,221);
         doc.rect(5, y, 200, 6, 'F');  
         y+=5
         doc.setFontType("bold");
         y+=5
         x=5
         doc.setFontSize(8);

         if(this.moneda!=1)         doc.text("TOTAL EN DOLARES ",x,y);
         else doc.text("TOTAL EN QUETZALES ",x,y);

         if (y>=pageHeight-35)
         {
           y=7;
           doc.addPage();                                           //x y footer, x y header
           this.header(doc,y)
        //   this.line(doc,y,2)
           doc.setFontSize(8);
           y=105;
         }


         x=x+50
         doc.text(""+this.enletras,x,y);
         y+=5
         doc.setFontSize(8);

         x=44
         doc.text("SUJETO A PAGOS TRIMESTRALES    AGENTES RETENEDORES DE IVA",x,y);
         this.line2(doc,y,2)
         y+=5
        // doc.text("Agente de Retención del IVA",x+5,y);
         y+=3
  if (y>=pageHeight-35)
            {
              y=7;
              doc.addPage();                                           //x y footer, x y header
              this.header(doc,y)
            //  this.line(doc,y,2)
              doc.setFontSize(8);
              y=105;
            }
         doc.setFontSize(7);
         doc.setFillColor(218,218,221);
         doc.rect(5, y, 200, 6, 'F');  
         y+=4              
         doc.setFontType("bold");
         doc.text("DATOS DEL CERTIFICADOR ",79,y);
         doc.setFontType("normal");
 
 
         y+=2      


         y+=5
         doc.text("NIT del contribuyente: ",8,y);
         doc.text("Nombre, razón o denominación social del contribuyente: ",70,y);
 
         //line
         this.line(doc,y,2)
         doc.line(50, y, 50, y+10);//vertical


         y+=5
         doc.text("5640773-4",14,y);        
         doc.text("GUATEFACTURAS, SOCIEDAD ANONIMA",70,y);
           //line
         this.line(doc,y,2)
         y+=2

         doc.setFillColor(218,218,221);
         doc.rect(5, y, 200, 5, 'F');
         
         
         y+=4
         
         if (y>=pageHeight-35)
         {
           y=7;
           doc.addPage();                                           //x y footer, x y header
           this.header(doc,y)
          // this.line(doc,y,2)
           doc.setFontSize(8);
           y=105;
         }


 
        // y+=2
         doc.setFontType("bold");
         doc.text("DATOS ADICIONALES ",79,y);
         doc.setFontType("normal");
 
 
         //y+=5     
       /*   doc.setFillColor(218,218,221);
         doc.rect(5, y, 200, 4, 'F');   */
         y+=4

         if (y>=pageHeight-35)
         {
           y=7;
           doc.addPage();                                           //x y footer, x y header
           this.header(doc,y)
          // this.line(doc,y,2)
           doc.setFontSize(8);
           y=105;
         }

         doc.setFillColor(218,218,221);
         doc.rect(5, y, 200, 5, 'F');  

         y+=3

         doc.setFontType("bold");
         doc.text("Campo ",7,y);
         doc.text("Valor ",79,y);
         doc.setFontType("normal");
         y+=5


         var serieadm=this.serie_adm
         var numroacceso=this.num_acceso
         var numeroadmin=this.numero_adm

         doc.text("Numero de Acceso ",7,y);
         doc.text(numroacceso,47,y);
         y+=5 
         doc.text("Serie Admin ",7,y);
         doc.text(serieadm,47,y);
         y+=5 
         doc.text("Numero Admin ",7,y);
         doc.text(numeroadmin,47,y);

         //si es en dolares
       

         if (y>=pageHeight-35)
         {
           y=7;
           doc.addPage();                                           //x y footer, x y header
           this.header(doc,y)
          // this.line(doc,y,2)
           doc.setFontSize(8);
           y=105;
         }


         if(this.moneda!=1){

          y+=5 
          doc.text("INCOTERM ",7,y);
          doc.text(this.INCOTERM,47,y);

          y+=5 
          doc.text("DESTINATARIO",7,y);
          doc.text(this.name1,47,y);
          y+=5 
          doc.text("CODIGODESTINA ",7,y);
          doc.text(this.CODIGODESTINA,47,y);



         if (y>=pageHeight-35)
         {
           y=7;
           doc.addPage();                                           //x y footer, x y header
           this.header(doc,y)
          // this.line(doc,y,2)
           doc.setFontSize(8);
           y=105;
         }


          y+=5 
          doc.text("NOMBCOMPRADOR ",7,y);
          doc.text(this.name1,47,y);

          y+=5 
          doc.text("DIRCOMPRADOR",7,y);
          doc.text(this.direccion2,47,y);

          y+=5 
          doc.text("CODCOMPRADOR",7,y);
          doc.text(this.CODCOMPRADOR,47,y);



         if (y>=pageHeight-35)
         {
           y=7;
           doc.addPage();                                           //x y footer, x y header
           this.header(doc,y)
          // this.line(doc,y,2)
           doc.setFontSize(8);
           y=105;
         }



          y+=5 
          doc.text("OTRAREF",7,y);
          doc.text("ENTREGA INMEDIATA",47,y);

          y+=5 
          doc.text("CODEXPORT",7,y);
          doc.text(this.CODEXPORT,47,y);


//this.  cambiolinea(y,pageHeight,doc)
         if (y>=pageHeight-35)
         {
           y=7;
           doc.addPage();                                           //x y footer, x y header
           this.header(doc,y)
          // this.line(doc,y,2)
           doc.setFontSize(8);
           y=105;
         }



          y+=5 
          doc.text("DIRDEST",7,y);
          doc.text(this.direccion2,47,y);




       }

       y+=5 
       doc.setFontSize(5);
x=5
this.line3(doc,y-5,2)



if(this.tip_doc!='F1'){

  doc.text("A "+this.dias_cr+" DIAS VISTA SE SERVIRAN UDS.  PAGAR  POR  ESTA  UNICA  FACTURA CAMBIARIA GIRADA LIBRE DE PROTESTO A LA ORDEN O ENDOSO DE  CELASA,  INGENIERIA Y EQUIPOS,  S.A.  EL  VALOR  TOTAL  POR  EL QUE ESTA ",7,y);
  y+=3 
  doc.text("EXTENDIDA O POR EL ULTIMO SALDO INSOLUTO QUE APAREZCA. LA MERCADERIA VIAJA POR CUENTA Y RIESGO DEL COMPRADOR. EL COMPRADOR ACEPTA   PAGAR  LOS  INTERESES  POR MORA   ESTIPULADOS  EN ESTA",7,y);
  y+=3
  
  if (y>=pageHeight-35)
           {
             y=7;
             doc.addPage();                                           //x y footer, x y header
             this.header(doc,y)
            // this.line(doc,y,2)
             doc.setFontSize(5);
             y=105;
           }

           var mon="Q"
           if(this.moneda!=1) mon="$"


  
  doc.text(" FACTURA. SE COBRARA EL 2.00 % MENSUAL SOBRE "+mon+" "+this.subtotal+" A PARTIR DE LOS "+this.dias_cr+" DIAS DESPUES DE  EMITIDA  ESTA  FACTURA. NO  SE  CONSIDERA  CANCELADA ESTA FACTURA  SI  NO  ESTA  AMPARADA POR EL  RECIBO ",7,y);
  y+=3
  doc.text(" OFICIAL DE LA EMPRESA, EXTENDIDO  POR  LA  CAJA, EL  COBRADOR O  VENDEDOR AUTORIZADO.   EL  COMPRADOR  ACEPTA  EL  VALOR  TOTAL  DE  ESTA  FACTURA   ELECTRONICA   CAMBIARIA   Y  SE COMPROMETE   A ",7,y);
  y+=3 
  doc.text("CANCELARLO AL VENCIMIENTO ACEPTADO EN LAS OFICINAS DEL VENDEDOR O DE TERCERA PERSONA QUE ESTE NOMBRADA  Y  EN CASO DE  INCUMPLIMIENTO  EL  COMPRADOR   RENUNCIA   EXPRESAMENTE AL  FUERO ",7,y);
  y+=3 
  if (y>=pageHeight-35)
           {
             y=7;
             doc.addPage();                                           //x y footer, x y header
             this.header(doc,y)
            // this.line(doc,y,2)
             doc.setFontSize(5);
             y=105;
           }
         
  
  doc.text("DE SU DOMICILIO Y SE SOMETE A LOS TRIBUNALES DEL DEPARTAMENTO DE GUATEMALA.LA FIRMA DE CUALQUIER EMPLEADO O DEPENDIENTE DEL COMPRADOR AL ACEPTAR ESTA FACTURA OBLIGARA A ESTE A CUMPLIR",7,y);
  y+=3 
  doc.text("CON TODAS LAS CONDICIONES ESTIPULADAS EN LAS MISMAS.",7,y);
  y+=5
  
  doc.setFontSize(6);
  
  doc.text("RECIBIDA DE COMFORMIDAD ____________________________________",130,y);
  y+=4
  doc.text("ACEPTADA LIBRE DE PROTESTO",163,y);
  
  y+=4
  this.line3(doc,y,2)
}



doc.setFontSize(8);
/* 
         INCOTERM:string=""
        // DESTINATARIO:string=""
         CODIGODESTINA:string=""
       //  NOMBCOMPRADOR :string=""
       //  DIRCOMPRADOR:string=""
         CODCOMPRADOR :string=""
         OTRAREF:string=""//entrega inmediata
         CODEXPORT:string=""
        // DIRDEST:string="" */
//var jss=new jsbard()

    //    JsBarcode("#itf", "12345678901237", {format: "itf"});


   

         var totpage=doc.internal.getNumberOfPages();   
         const pageCount = doc.internal.getNumberOfPages();
         this.ver("apgecounte es "+pageCount)
         for(var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
            doc.text("Pagina "+String(i)+" de "+totpage,93,285);
        }
      
       //const Canvas = document.getElementById("chart");
        
     /*    const canvas = document.getElementById('map1') as HTMLCanvasElement;
        const jpegUrl = canvas.toDataURL('image/jpeg');
        doc.addImage(jpegUrl, 'JPEG', 20, 150, 50, 50);
  */


         window.open(doc.output('bloburl'), '_blank');

     //    var res = doc.output('datauristring');  
     var pdf = doc.output('blob');

         this.sendfilest_tar(pdf,this.numero)
         this.mensaje("PDF GENERADO CORRECTAMENTE","success")
   }


   cambiolinea(y,pageHeight,doc){

    if (y>=pageHeight-35)
    {
      y=7;
      doc.addPage();                                           //x y footer, x y header
      this.header(doc,y)
     // this.line(doc,y,2)
      doc.setFontSize(8);
      y=105;
    }

   }
   fileToUpload_tar:any
   enviar(){

    let formData = new FormData(); 
    formData.append("file", this.fileToUpload_tar, "test.jpg"); 


     /*  this.http.post(“Your end-point URL”, formData).subscribe((val) => {
    
    console.log(val);
    }); */


    var url=this.api.geturlapi()+'/Factura/upload';
    this.api.data_post(url,formData)
    .subscribe((data1: any) => {
        this.ver("inn  "+JSON.stringify(data1));
  
    
     /*  if(data1["status"]==false){
        this.ver("va a error");this.mensaje1=data1["message"];
      
      }
      else  this.processsubcta(data1); */
  
    },
    error =>{ 
      console.error("couldn't post because", JSON.stringify(error));      
  
      // this.openSnackBar('No existe el doc ','ERROR'); 
    })
  

   }
 postMethodtarea(files: FileList) {
    this.fileToUpload_tar = files.item(0); 
  }
   sendfilest_tar(filename,name) {
    //this.fileToUpload =this.fileuno; 
     let formData = new FormData(); 
     formData.append("file",  filename,name+".pdf"); 
 
 
      /*  this.http.post(“Your end-point URL”, formData).subscribe((val) => {
     
     console.log(val);
     }); */
 
 
     var url=this.api.geturlapi()+'/Factura/upload';
     this.api.data_post(url,formData)
     .subscribe((data1: any) => {
         this.ver("OK inn  "+JSON.stringify(data1));
   
     
      /*  if(data1["status"]==false){
         this.ver("va a error");this.mensaje1=data1["message"];
       
       }
       else  this.processsubcta(data1); */
   
     },
     error =>{ 
       console.error("couldn't post because", JSON.stringify(error));      
   
       // this.openSnackBar('No existe el doc ','ERROR'); 
     })
   
     return false; 
     }



   line(doc,y,a){

    //line
    doc.setDrawColor(201,201,211);
    doc.setLineWidth(0.3); 
    doc.line(5, y+a, 205, y+a);
    //end line

   }
   line2(doc,y,a){

    //line
    doc.setDrawColor(163,163,171);
    doc.setLineWidth(0.3); 
    doc.line(5, y+a, 205, y+a);
    //end line

   }
   line3(doc,y,a){

    //line
    doc.setDrawColor(0,0,0);
    doc.setLineWidth(0.3); 
    doc.line(5, y+a, 205, y+a);
    //end line

   }

  ngOnInit() {
  


  }
  
}
