import { Component, ViewChild, OnInit ,OnChanges, Inject} from '@angular/core';

//import {MAT_DIALOG_DATA} from '@angular/material';

import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import {SESSION_STORAGE } from 'angular-webstorage-service';

import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
//import { FuncionesComponent} from '../../pages/clientes/funciones/funciones.component';
import { DOCUMENT } from '@angular/common'; 
import { environment } from  '../../../environments/environment';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal2 from 'bootstrap-sweetalert/dist/sweetalert.js'
import { ThrowStmt } from '@angular/compiler';

export interface DialogData {
  empresa1: '1';
  clicod: '0';
}
@Component({
  selector: 'app-metodos',
  templateUrl: './metodos.component.html',
  styleUrls: ['./metodos.component.scss']
})

export class MetodosComponent implements OnInit{
  mensajedisplayed:string;
  public form: FormGroup;
  public form2: FormGroup;
  planModel: any = {start_time: new Date() };
  data:any;

 
  constructor(private router:Router,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,private fb: FormBuilder,
    @Inject(SESSION_STORAGE) private sesion: WebStorageService,
private httpClient: HttpClient
 
 ) {

 // this.urllocal="http://192.177.50.50/apiproducto/index.php"
   // Object.assign(this, {single1, single2, multi1, multi2, multi3});
    
  }


 


  getdata(code){

    if(code==1)return this.storage.get('cod_emp');
    if(code==2)return this.storage.get('user');
    if(code==3)return this.storage.get('token');
    if(code==4)return this.storage.get('user_id');
    if(code==5)return this.storage.get('depocod');
    if(code==6)return this.storage.get('fecharep');
    if(code==7)return this.storage.get('fecha');
    if(code==8)return this.storage.get('vendedor');
    if(code==9)return this.storage.get('pass');
    if(code==10)return this.storage.get('user2');
    if(code==11)return this.storage.get('userR');
    if(code==12)return this.storage.get('passR');
    if(code==13)return this.storage.get('urlapi');


  }

  eslogueado(){

   var a= this.storage.get('user')
if(a!=null){

  var b:string=a
  if(b.length>0) return true
  else return false
}else return false

  }
  nextInputIndex:any;
  public handleKeyDown(event: any, id)
{
  if (event.keyCode == 13)
  {
    event.preventDefault();
    //console.log("se dio click a enter ");

    let input = event.target;
   // console.log("input es  "+input);
    var integer1 = parseInt(id);
    if(integer1<10)var number1=id.charAt(3);
    else{
      var number10=id.charAt(3);
      var number2=id.charAt(4);

      var number1=number10+number2;

    } 
  } 
}


ver(a){

  console.log(a);
}


        users: UserData[]
elegiremp(username,userid){

 

 // this.httpClient.get( 'http://192.177.50.50/pruebasapi/index.php/api/User/usuario_empresas/'+username,  {headers: headers}).subscribe(
    this.data_get( this.geturlapi()+'/User/usuario_empresas/'+username+"/"+userid).subscribe(
 
  (data1) => {
        console.log("inn "+JSON.stringify(data1));
  
      // console.log("   ");
  
      //  console.log("el status es "+data1["status"]);
        
  
        if(data1["estado"]!=null){
  
          if(data1["estado"]=='true' ) {
            console.log("si tiene permisos");

            this.users = [];

            console.log(" empresas2 es "+ JSON.stringify(data1["empresas"]));


              var keys = Object.keys(data1["empresas"]);

              console.log(" keys.length empresas es "+ keys.length);

              for (var i = 0; i < keys.length; i++) {
              var value = data1["empresas"][i];
              console.log(value);

              //console.log(" nombre es "+ value["clicod"]);
              console.log(" nombre es "+ value["NOMBRE"]);
              console.log(" CODIGO es "+ value["EMPRESA"]);
              console.log(" DEPOCOD es "+ value["DEPOCOD"]);

              this.users.push(this.agregaremp(value["EMPRESA"], value["NOMBRE"], value["DEPOCOD"]));

              //console.log('clicod');
              // console.log(value);
              // your code here...
              }

      
/* 
              for (var i = 0; i < users.length; i++) {
               
              if(i==0) { emp1= users[i]["NOMBRE"]; cod1=users[i]["CODE"];dep1=users[i]["DEPOCOD"];}
              if(i==1) { emp2= users[i]["NOMBRE"]; cod2=users[i]["CODE"];dep2=users[i]["DEPOCOD"];}

              if(i==2) { emp3= users[i]["NOMBRE"]; cod3=users[i]["CODE"];dep3=users[i]["DEPOCOD"];}

              if(i==3) { emp4= users[i]["NOMBRE"]; cod4=users[i]["CODE"];dep4=users[i]["DEPOCOD"];}

              if(i==4) { emp5= users[i]["NOMBRE"]; cod5=users[i]["CODE"];dep5=users[i]["DEPOCOD"];}

                }
              */

          //    this.elegiw(users,users[0]["EMPRESA_ID"],users[1]["EMPRESA_ID"],users[2]["EMPRESA_ID"],users[3]["EMPRESA_ID"],users[4]["EMPRESA_ID"]);
          this.mostrar_panel_elegir_empresas();

            //this.elegiw();

          }      else   {
                      console.log(data1["message"]+","+data1["status"]);
                      this.router.navigate(['/extra-layout/sign-in', { mensaje:this.sesionmsn()}]);
                  }
        
        }
        else  {
          console.log(data1["message"]+","+data1["status"]);
          this.router.navigate(['/extra-layout/sign-in', { mensaje:this.sesionmsn()}]);
      }
  
      },
      err => {console.log('ocurrio un err en catach '+err);
    
    
      var dato_ret= JSON.stringify(err);
  
      
  
      console.log("meesage es "+err["message"]);
      console.log("status es "+err["status"]);
  
      this.router.navigate(['/extra-layout/sign-in', { mensaje:this.sesionmsn()}]);
  
    //  this.mensaje("OCURRIO UN ERROR AL INGRESAR, VERIFIQUE SUS CREDENCIALES","error")
    }
      
      ); 

}

public mostrar_panel_elegir_empresas(){




}
// barChart
saveInLocal(key, val): void {
  console.log("va a grabar es "+key +" con codigo "+val);
 // this.display('recieved= key:' + key + 'value:' + val);
  this.storage.set(key, val);
 // var a= this.storage.get(key);
  //this.data[key]= a;
 }

 saveinsession(key,val){
/* var expirationInMin=120;
  let expirationDate = new Date(new Date().getTime() + (60000 * expirationInMin))
    let newValue = {
    value: val,
    expirationDate: expirationDate.toISOString()
  } */

  this.sesion.set(key, val);


 }
 getsession(key){
  return  this.sesion.get(key);
 }
private agregaremp(CODE,NOMBRE,DEPOCOD) {

  //console.log("a es "+a.Empresa+" clicod es "+a.clicod);
  
    return {
    
    CODE: CODE,
    NOMBRE: NOMBRE,
    DEPOCOD: DEPOCOD, 
    };
  }


navegar(name){

  this.router.navigate([name]);

}

fechahoy(){

  let dat= this.convertirdate(this.planModel.start_time);

return dat
}

convertirdate(fechaconv){

  let date = JSON.stringify(fechaconv)
date = date.slice(1,11)
return date;
}
validadia(dayl,mesl){


  var dat=new FormData()
  
    
    var url=this.geturlapi()+'/Documento/feriados';
    this.data_post(url,dat)
    .subscribe((data1: any) => {
      this.ver("inn  "+JSON.stringify(data1));
  
      var respons=data1.status;
      if(respons==true){

        data1["data"].forEach(element => {
          
          var fechadia=element.FECHAF

          let newDate = new Date(fechadia)

          let diaf=newDate.getDay()
          let mesf=newDate.getMonth()

          this.ver("va a diferenciar dia= "+diaf+" y mes="+mesf+" con "+dayl+" y "+mesl)


          if(diaf==dayl && mesf==mesl){
            
          }
        });
     
      }else {
      
        
      }
  
  
    },
    error =>{ 
      console.error("couldn't post because", JSON.stringify(error));      
      
      // this.openSnackBar('No existe el doc ','ERROR'); 
    })
  
  
  
  
  }


  data_postip(url,data) {

  
    return this.httpClient.post( url, data);
    
  }
  data_post(url,data) {

    var tok=this.getdata(3);
    let headers = new HttpHeaders().set('Authorization', "ddddd");
  
    return this.httpClient.post( url, data,  {headers: headers});
    
  }
  data_postwith(url,data) {

    var tok=this.getdata(3);
    //let headers = new HttpHeaders().set('Authorization', tok);
    let headers  = new HttpHeaders().set("Content-Type", "application/json");

    return this.httpClient.post( url, data,  {headers: headers});
    
  }
  
  data_get(url) {

    var token= this.storage.get('token');
  
    let headers = new HttpHeaders().set('Authorization', token);
  
    return this.httpClient.get( url,  {headers: headers});
    
  }

  data_permisos() {

    var tok= this.storage.get('token');
    var username= this.storage.get('user');

    var userid= this.storage.get('user_id');

    var token= this.storage.get('token');
      
    let headers = new HttpHeaders().set('Authorization', token);
    return this.httpClient.get( this.geturlapi()+'/User/usuario_empresas/'+username+"/"+userid,  {headers: headers});
        
  }
estalog(){

    var user1= this.storage.get('user');
    var token1= this.storage.get('token');

  this.ver("user es "+user1)
  this.ver("token es "+token1)


  if(user1!=null){

    if(user1.length>0){


      if(token1!=null){

        if(token1.length>0)return true
    
        
      }
    }

    
  }

 
 return false

}
 
  ngOnInit() {
  }

  public mensaje(a,tipo){

   
    
  /* 
    if(tipo=="success")Swal.fire('Modificado exitosamente')
    if(tipo=="warning")Swal.fire('ocurrio un error')
  
  
  
      var tipo2=tipo;
      if(tipo=="warning")tipo2="AVISO"
      if(tipo=="success")tipo2="CORRECTO"
      
          Swal.fire({
                type: tipo,
            title: tipo2,
            text: a,
            timer: 2000
          })
         */
  
       
  
  
    }
  



 /*  geturlapi(): string{
    return  this.geturlphp()+"/pruebasapi/index.php/api";
  }

geturlphp(): string{

  return "http://192.177.50.50"
  }
  geturldist(): string{

    } */
sesionmsn(){

  return "SU SESION HA EXPIRADO, INICIE SESION NUEVAMENTE";
}



redirigir(pagina){

  this.router.navigate(['/'+pagina]);
}

redirigirextra(pagina,b){

  this.router.navigate(['/'+pagina, { mensaje:b}]);
}

// this.api.redirigir("permisos")


subirdata(formData){
  let headers = new HttpHeaders()
  headers.append('Content-Type', 'multipart/form-data');
  headers.append('Accept', 'application/json');


 // let headers = new HttpHeaders().set('Authorization', tok);
  
   //this.httpClient.post( url, data,  {headers: headers});

  // let options = new RequestOptions({ headers: headers });

 // let options = new RequestOptions({ headers: headers });
 return this.httpClient.post('filefolder', formData,   {headers: headers})
    



}

getip2(){


this.ver("entrara a ip 2")
this.httpClient.get( 'https://jsonip.com/').subscribe(data => {


  var ip= data["ip"];
  //ip= "200.434.34343";
  this.ver("ip es "+ip)

  if(ip.indexOf("192.177") == -1){
    //this.urllocal="http://200.30.142.253/apiproducto/index.php"
    this.ver("es remota ...")
  } else{
    this.ver("es local la ip")
  }



  console.log(data);
  });

}




    getIpAddress() {

/* 
      this.data_get.get('https://jsonip.com/').subscribe(data => {
console.log(data);
}); */


    this.saveInLocal('urlapi', "http://192.177.50.67/api_fel/index.php/api");

    var url="http://200.30.142.253/apiproducto/index.php/Producto/verip/"; 
  //  var url=this.geturlapi()+"/Producto/verip/"; 

    this.data_postip(url,null)
    .subscribe((data: any) => {

    this.ver("inn "+JSON.stringify(data));
    var ip= data["ip"];
    //ip= "200.434.34343";
    this.ver("ip es "+ip)

    if(ip.indexOf("192.177") == -1){
      //this.urllocal="http://200.30.142.253/apiproducto/index.php"
      this.ver("es remota ..."+this.urllocal)

      this.saveInLocal('urlapi', "http://200.30.142.253/api_fel/index.php/api");

    } else{
      this.ver("es local la ip")
    }
  
    },

      
    error =>{ 

      
    console.error("couldn't post because", JSON.stringify(error));      


      
    // this.openSnackBar('No existe el doc ','ERROR'); 
    })

    }


    urllocal:string=""
    urllocal2:string=""

   
    geturlapi(){
      
    //  return this.urllocal

     return "http://192.177.50.67/api_fel/index.php/api"
//return this.getdata(13)
      }
      geturlapi2(){
      
        // return this.urllocal
   return "http://192.177.50.67/api_fel/api"
   
         
     }

      geturllogo(){
      
        // return this.urllocal
   return "http://192.177.50.67/api_fel/api"
   
         
         }
    
paso:boolean=false
    seturllocal(){



      
    }
    getapi2(){

       //  return "http://192.177.50.50/productos_img";
         return "http://produtil.celasa.com.gt";
        
  
    }

   getapi20(){

       // return "http://192.177.50.50/productos_img/index.php";
       return "http://produtil.celasa.com.gt/index.php";
          
    
    }

   getdatax(x,dat):number{

    return x+dat;
}
getdatay(y,dat):number{

    return y+dat;
}



//para comp pago


//para facsaldo recibos

private createNewrowfac(  a:any
  ) {
  
      return {
        factura: a.FACTURA,
        valorf: a.VALOR,
        fechaf: a.FECHA,

      };
    }
    


comparar(){

  var a:boolean=true;

    try{
      var object = JSON.parse(this.getdata(7)),
        dateString = object.timestamp,
        now = new Date().getTime().toString();

    this.ver("va a comparar actual= "+now+" con guardado "+dateString);
    var a:boolean=this. timeDifference(now,dateString);
    if(a==false){
    //destruir sesion
    this.destroysession()
 //   this.redirigir('ingresar','');
    //this.mensajes("Su sesión ha caducado ,por favor inicie sesión nuevamente","warning");
    this.redirigirextra('ingresar',"Su sesión ha caducado ,por favor inicie sesión nuevamente");
    }

    }catch(Exception ){
        a=false;
    
    }

    return a;
}
 


 
timeDifference(date1,date2) {

var resolution = date1 - date2
var resolutionTime = (resolution / 1000/60)
console.log("la dif es "+resolutionTime)

var enter=+resolutionTime;

//if(enter>1)return false



// si es mas de 60 minutos
if(enter>60)return false
else return true;
 }


 destroysession(){
  this.saveInLocal('user', '');
  this.saveInLocal('token',  '');
  this.saveInLocal('ses_id',  '');
  this.saveInLocal('user_id',  '');
  this.saveInLocal('empresa',  '');
  this.saveInLocal('cod_emp',  '');
  this.saveInLocal('fecharep',  null);
  this.saveInLocal('fecha',  '');
  this.saveInLocal('depocod',  '');
  this.saveInLocal('nivel',  '');
  this.saveInLocal('vendedor',  '');
  this.saveInLocal('pass',  '');
  this.saveInLocal('user2',  '');

  this.saveInLocal('passR',  '');
  this.saveInLocal('userR',  '');
  this.saveInLocal('urlapi','');
  
 }
 





  
 
}


export interface bodyctarec {
  factura: string;
  valorf: string;
  fechaf: string;
 
}
export interface UserData {
  CODE: string;
  NOMBRE  : string;
  DEPOCOD: string;
}